import axios from "axios";

const http = axios.create({
    baseURL: 'https://enterprisesystemadmin.aixuesheng.net/api/'
    // baseURL: 'http://192.168.31.173:8080/api/'
})

// http.interceptors.request.use((config) => {
//     if(sessionStorage.getItem('token')) {
//         config.headers.authorization = sessionStorage.getItem('token')
//     }
//     return config
// }, (error) => {
//     return Promise.reject(error)
// })

// http.interceptors.response.use((res) => {
//     const { data } = res
//     if(data.meta.status !== 200 && data.meta.status !== 201) {
//         ElMessage({
//             showClose: true,
//             message: data.meta.msg,
//             type: 'error'
//         })
//     }
//     return res
// }, (error) => {
//     return Promise.reject(error)
// })

export const get = (url, params) => {
    return http.get(url, {params})
}

export const post = (url, data) => {
    return http.post(url, data)
}

export const update = (url, data) => {
    return http.put(url, data)
}

export const del = (url) => {
    return http.delete(url)
}